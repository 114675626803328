/* Custom */
.main_header {
  padding: 0px;
}

.hide {
  display: none !important;
}

.sidebar-nav-wrapper .navbar-logo {
  width: 127px;
  height: auto;
}

.sidebar-nav-wrapper .navbar-logo img {
  width: 100%;
  height: auto;
}

span.model_name_set {
  color: black;
  background-color: #ffff67;
  margin: 5px 8px;
}

.black {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #0000009c;
}

.qty {
  text-align: center;
  width: 24px !important
}

.invoice-card {
  background: #e7e7e7;
}

.job-active {
  background-color: #f7ff76;
  --bs-table-bg: #f7ff76;
}

.job-canceled {
  background-color: #ffc9c9;
  --bs-table-bg: #ffc9c9;
}

.job-done {
  background-color: #97ff76;
  --bs-table-bg: #97ff76;
}

.job-on-hold {
  background-color: #cbcbff;
  --bs-table-bg: #cbcbff;
}

input.price {
  text-align: right;
}

.invoice-for.logo {
  width: 200px;
  height: 200px;
}

.print .logo {
  height: 150px;
  width: 150px;
}

.invoice-for.logo img {
  width: 100%;
}

.inv-pending {
  background-color: red !important;
}

.inv-pending p {
  color: white !important;
  text-align: center;
}

.inv-payed {
  background-color: green !important;
}

.inv-payed p {
  color: white !important;
  text-align: center;
}

.part-deactivated {
  text-decoration: line-through;
  background-color: initial !important;
}

.service-deactivated {
  text-decoration: line-through;
}

.st-0 {
  background-color: #f9b2b2;
}

#invoice.print .price p {
  text-align: right;
  font-weight: bold;
}

#invoice.print .headings {
  background-color: black;
}

#invoice.print .headings h6 {
  color: white;
}

#invoice.print .headings .price,
#invoice.print .headings .total {
  text-align: right;
}

#invoice.print table td p {
  line-height: 1.0;
}

.invoice_header {
  height: 100px;
}

.table.top p {
  margin-bottom: 0rem;
}

.dis-price {
  text-align: right;
}

.dis-price.tot {
  font-weight: bold;
}

.quick_button {
  margin-top: 30px;
}

.shops .menu-toggle-btn {
  display: none;
}

.main-wrapper.shops {
  margin-left: 0px;
}

.loading-content {
  position: fixed;
  z-index: 1000;
  background-color: #0000005c;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  display: none;
}

.loading {
  left: 50%;
  position: relative;
  top: 50%;
}

.loading-content.show {
  display: block;
}

.on-filter-year {
  display: none;
}

.on-main-filter-all {
  display: none;
}

.gray {
  background-color: #f1f1f1;
}

.report-section {
  border-top: 2px dotted #000000;
  padding-top: 20px;
  margin-top: 20px;
}

input.error-red-input {
  border-color: red;
}

.de-hidden .part-deactivated {
  display: none;
}

.st-hidden .st-0 {
  display: none;
}

.pagination .page-link {
  cursor: pointer;
}

.user_dash_shop_logo {
  width: auto;
  height: 45px;
}